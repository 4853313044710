// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import App from './App';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import axios from 'axios';
import VueAxios from 'vue-axios';
import Notifications from 'vue-notification';
import * as Sentry from '@sentry/vue';
import './filters.js';

Vue.config.devtools = true;
Vue.config.productionTip = false;

const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

axios.defaults.headers.common = {
  'X-Requested-With': 'XMLHttpRequest',
  'X-CSRF-TOKEN': csrfToken
};

Vue.use(VueAxios, axios);
Vue.use(Notifications);

const eventHub = new Vue({
  data: () => ({
    appMetadata: {}
  })
});
Vue.prototype.$eventHub = eventHub;

axios.interceptors.request.use(
  request => {
    eventHub.$emit('before-request', request.url);
    return request;
  },
  error => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => {
    eventHub.$emit('after-response');
    return response;
  },
  error => {
    eventHub.$emit('after-response');
    return Promise.reject(error);
  }
);

router.beforeEach((to, from, next) => {
  if (from.name !== 'FeelFreeToChangeThisRouteName') {
    Vue.notify({
      group: 'main',
      clean: true
    });
  }
  next();
});

Sentry.init({
  Vue,
  dsn: process.env.SENTRY_DSN_FRONTEND,
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration()
  ],
  // Tracing
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/auth\.luceosports\.com/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: "production",
});

/* eslint-disable no-new */
new Vue({
  router,
  store,
  vuetify,
  components: { App },
  created: function() {
    if (window.user) store.commit('setUser', window.user);
    if (window.otherUser) store.commit('setOtherUser', window.otherUser);
    if (window.client) store.commit('setClient', window.client);
    if (window.extraData) store.commit('setExtraData', window.extraData);
    store.commit('setConfig', window.config);
    store.commit('setCustomer', window.customer);
    store.commit('setCsrfToken', csrfToken);
  },
  render: (h) => h(App),
}).$mount('#app');
